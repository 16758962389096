

:root {
    --co-primary: #171717;
    --co-primary-dark: #02292B;
    --co-primary-light: #02292B8C;
    --co-red: #EC5865;
}

.input {
    width: 100%;
    position: relative;
}

.input .input__title {
    position: absolute;
    top: -15%;
    left: 10px;
    color: var(--co-primary);
    background-color: white;
    opacity: 0%;
    padding: 0px 5px;
    font-size: 14px;
}

.input__field:focus + .input__title,
.input__field.--filled + .input__title {
    opacity: 100%;
    animation: label-rise linear 0.2s;
}

.input .input__field.--error + .input__title {
    color: var(--co-red) !important;
}

@keyframes label-rise {
    0%{
        opacity: 0;
        top: 10%
    }
    100%{
        opacity: 1;
        top: -20%
    }
}

.input .input__field {
    padding: 15px 10px;
    width: 100%;
    border: 1px solid var(--co-primary);
    border-radius: 8px;
    opacity: 32%;
    font-weight: 400;
    color: var(--co-primary);
}

.input .input__field:focus,
.input .input__field.--filled {
    outline: none;
    border: 1px solid var(--co-primary);
    color: var(--co-primary);
    opacity: 100%;
}

.input .input__field.--error {
    border-color: var(--co-red);
    color: var(--co-red);
}

.input .input__field::placeholder {
    color: var(--co-primary) !important;
}

.input .--error::placeholder {
    color: var(--co-red) !important;
}

.input .input__field:focus::placeholder {
    opacity: 0.32;
}

.input .input__icon {
    position: absolute;
    right: 20px;
    top: 35%;
    color: var(--co-primary);
    opacity: 0.32;
    cursor: pointer;
    z-index: 99;
}

.input .input__icon:hover {
    opacity: 100% !important;
}