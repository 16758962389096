@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Rift';
  src: local('Rift'), url("./assets/fonts/rift-cufonfonts/Rift Regular.otf") format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'RiftBold';
  src: local('RiftBold'), url("./assets/fonts/rift-cufonfonts/Rift Bold.otf") format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Calibre';
  src: local('Calibre'), url("./assets/fonts/calibre/CalibreRegular.otf") format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Calibre SBold';
  src: local('Calibre SBold'), url("./assets/fonts/calibre/CalibreSemibold.otf") format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Calibre Bold';
  src: local('Calibre Bold'), url("./assets/fonts/calibre/CalibreBold.otf") format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Calibre";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rift {
  font-family: "Rift";
}

.rift-bold {
  font-family: "RiftBold";
}

.calibre {
  font-family: "Calibre";
}

.calibre-sbold {
  font-family: "Calibre SBold";
}

.calibre-bold {
  font-family: "Calibre Bold";
}

.ani-border-bottom{
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0px;
  animation: bottom-ani linear 12s;
  transition: all 3s;
  -moz-transition: all 3s;
  -webkit-transition: all 3s;
  opacity: 0%;
}

@keyframes bottom-ani {
  0%{
    max-height: 50%;
    opacity: 80%;
  }
  
  10%{
    max-height: 3px;
    background-color: white;
  }

  100%{
    max-height: 3px;
    opacity: 100%;
    background-color: white;
  }
  
}

.banner2{
  background-image: linear-gradient(rgba(0, 31, 58, 0.7),rgba(0, 31, 58, 0.7)) , url("../public/assets/images/chevrolet_night.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .banner2{
    background-image: none;
    background: none;
  }
}

.ani-image-grow{
  animation: image-grow linear 8s;
  background-size: 105% 105%;
  transition: all;
}

@keyframes image-grow {
  from{
    background-size: 100% 100%;
  }
  to{
    background-size: 105% 105%;
  }
}

.services {
  background-image: url("/public/assets/images/car-trail-bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 90% 0%;
}

.contact {
  background-image: url("/public/assets/images/contactBg.svg");
  background-size: 50% 80%;
  background-position: 10% 30%;
  background-repeat: no-repeat;
  /* color: red; */
  /* background-color: green; */
  /* clip-path: polygon(0% 10%, 75% 30%, 100% 100%, 0% 100%) inset(0% 45% 0% 45% round 10px); */
  /* inset: 0% 45% 0% 45% round 10px; */
  /* overflow: hidden; */
}

@media (max-width: 767px) {
  .services {
    /* background-image: none; */
    background-position: 100% 100%;
  }
}